<template>
    <div>
        <v-container fluid class="grid-list-xl pt-0 mt-12" v-if="responsive == 'PC'">
            <v-row class="align-center justify-center">
                <v-col cols="12" md="9" class="px-0 mt-3">
                    <v-card flat tile class="pt-5">
                        <h3 class="text-center">{{ $t('defy.iobDefyHedgedPool') }}</h3>
                        <v-col cols="12" class="px-0 mt-5 mx-5">
                            <v-row class="mx-5">
                                <v-col cols="12" sm="12" md="12" class="pl-0 pr-11">
                                    <!-- <defy-hedged-pool-history-line-chart v-show="type=='history'"></defy-hedged-pool-history-line-chart>
                                    <defy-hedged-pool-recent-line-chart v-show="type=='recent'"></defy-hedged-pool-recent-line-chart> -->
                                    <defy-hedged-pool-nav-line-chart></defy-hedged-pool-nav-line-chart>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-tabs class="mt-5" v-model="type" align-with-title :slider-size="5" slider-color="lightred">
                            <v-tab href="#history">{{ $t('portfolio.history') }}</v-tab>
                            <v-tab href="#recent">{{ $t('portfolio.recent') }}</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="type" class="mt-5">
                            <v-tab-item value="history">
                                <defy-performance-history></defy-performance-history>
                            </v-tab-item>
                            <v-tab-item value="recent">
                                <defy-performance-recent></defy-performance-recent>
                            </v-tab-item>
                        </v-tabs-items>

                        <!-- <v-col cols="12" class="px-0 mt-5 mx-5">
                            <v-row class="mx-5">
                                <v-col cols="12" sm="12" md="12" class="pl-0 pr-11">
                                    
                                </v-col>
                            </v-row>
                        </v-col> -->
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid class="flex-b grid-list-xl mt-12 pb-0 px-0 pt-0" v-if="responsive == 'mobile'">
            <signal-mobile-change></signal-mobile-change>
            <v-row class="align-center justify-center mx-4">
                <v-col cols="12" md="12" class="px-0 mt-3 pb-16">
                    <v-card flat tile class="pt-5">
                        <h3 class="text-center">{{ $t('defy.iobDefyHedgedPool') }}</h3>
                        <v-col cols="12" class="mt-5">
                            <v-row class="mx-5">
                                <v-col cols="12" sm="12" md="12" class="pl-0">
                                    <!-- <defy-hedged-pool-history-line-chart v-show="type=='history'"></defy-hedged-pool-history-line-chart>
                                    <defy-hedged-pool-recent-line-chart v-show="type=='recent'"></defy-hedged-pool-recent-line-chart> -->
                                    <defy-hedged-pool-nav-line-chart></defy-hedged-pool-nav-line-chart>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-tabs class="mt-5" v-model="type" align-with-title :slider-size="5" slider-color="lightred">
                            <v-tab href="#history">{{ $t('portfolio.history') }}</v-tab>
                            <v-tab href="#recent">{{ $t('portfolio.recent') }}</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="type">
                            <v-tab-item value="history">
                                <defy-performance-history></defy-performance-history>
                            </v-tab-item>
                            <v-tab-item value="recent">
                                <defy-performance-recent></defy-performance-recent>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- <mobile-btn></mobile-btn>  -->
    </div>
</template>
<style>

</style>
<script>
    import Vue from 'vue';
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import MobileBtn from "@/components/common/MobileBtn";
    import signalMobileChange from "@/components/signal/SignalMobileChange";
    import DefyPerformanceHistory from "@/components/performance/DefyPerformanceHistory";
    import DefyPerformanceRecent from "@/components/performance/DefyPerformanceRecent";
    import DefyHedgedPoolHistoryLineChart from "@/highcharts/DefyHedgedPoolHistoryLineChart";
    import DefyHedgedPoolRecentLineChart from "@/highcharts/DefyHedgedPoolRecentLineChart";
    import DefyHedgedPoolNavLineChart from "@/highcharts/DefyHedgedPoolNAVLineChart";
    export default {
        data(){
            return {
                type:"history",
            }
        },
        components:{
            MobileBtn,signalMobileChange, DefyPerformanceHistory, DefyPerformanceRecent, DefyHedgedPoolHistoryLineChart, DefyHedgedPoolRecentLineChart, DefyHedgedPoolNavLineChart
        },
        created(){
            this.$store.commit('signalPageHandler', this.$t("menu.defyHedgedPool"));
            this.$store.dispatch("mobileBtnPageHandler",1);
        },
        mounted(){

        },
        computed: {
            ...mapGetters(["responsive"])
        },
        watch:{

        },
        methods: {
            
        },
    }
</script>
