<template>
<div>
    <div :id="id" style="height: 360px" v-if="responsive == 'PC'"></div>
    <div :id="id" style="height: 290px" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
</div>
</template>

<style>
svg.highcharts-root {
    font-family: "Noto Sans SC", sans-serif !important;
}

.test tspan:active {
    text: expression(target="_blank");
}
</style>

<script>
import config from '@/config.js';
import Highcharts from 'highcharts/highstock';
import Bus from '@/components/bus/Bus';

import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            id: 'defyHedgedPoolHistoryLine',
            chart: null,
            loading: false,
            performance:0,
            option: {
                // 图表类型
                chart: {
                    backgroundColor: this.darkMode ? 'dark' : '', 
                    renderTo: 'defyHedgedPoolHistoryLine',
                    type: 'line',
                    // x方向和y方向都可以用鼠标拖拽放大
                    zoomType: 'xy'
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#9E9E9E'
                    },
                },
                subtitle: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#999999'
                    },
                },
                // x轴
                xAxis: {
                    categories: [],
                    lineWidth: 1,
                    tickPosition: 'inside',
                    type: 'category',
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S',
                        second: '%m/%d/%Y %H:%M:%S',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d',
                        week: '%m/%d',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    labels: {
                        enabled: true
                    }
                },
                // y轴
                yAxis: {
                    title: {
                        text: null
                    },
                    visible: true,
                    // 横线宽度设置为0表示不显示横线
                    gridLineWidth: 0,
                    opposite: true,
                    offset: 30,
                    labels: {
                        align: "right",
                        x: -3,
                        formatter: function () {
                            return this.value.toFixed(0) + "%";
                        }
                    },
                },
                // 鼠标悬浮提示框
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: '%',
                    followTouchMove: true,
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S UTC',
                        second: '%m/%d/%Y %H:%M:%S UTC',
                        minute: '%m/%d/%Y %H:%M UTC',
                        hour: '%m/%d/%Y %H:%M UTC',
                        day: '%m/%d/%Y %H:%M UTC',
                        week: '%m/%d %H:%M UTC',
                        month: '%m/%Y',
                        year: '%Y'
                    }
                },
                // 数据
                series: [{
                    // type: 'spline',
                    name: "RoE",
                    data: [],
                    color: {
                                linearGradient: {
                                  x1: 0,
                                  x2: 0,
                                  y1: 0,
                                  y2: 1
                                },
                                stops: [
                                  [0, '#4CAF50'],
                                  [1, '#FEE108']
                                ]
                              },
                    lineWidth: 3
                }],
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                // 图例导航
                legend: {
                    enabled: false,
                    align: 'left',
                    itemStyle: {
                        color: '#999999'
                    },
                    itemHoverStyle: {
                        color: '#999999'
                    },
                    itemHiddenStyle: {
                        color: '#606063'
                    },
                    title: {
                        style: {
                            color: '#C0C0C0',
                            fontFamily: '\'Noto Sans SC\', sans-serif'
                        }
                    }
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: 'black',
                        theme: {
                            fill: '#EEEEEE',
                        },
                    }
                },
                // 图形参数
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        cursor: 'pointer',
                        marker: {
                            enabled: false
                        }
                    }
                },
                // 版权信息
                credits: {
                    enabled: false,
                    href: config.fund,
                    text: 'View on iobots.pro',
                    className: 'a',
                    position: {
                        x: -12,
                        y: -5
                    },
                    style: {
                        fontSize: '12px',
                        color: 'white',
                        target: '_black'
                    }
                },
                exporting: {
                    enabled: true,
                    filename: 'Defy-Hedged-Pool-History',
                    buttons: {
                        contextButton: {
                            align: "left",
                            verticalAlign: "top",
                            x: -10,
                            y: 0
                        }
                    }
                },
                loading: {
                    style: {
                        backgroundColor: 'silver'
                    },
                    labelStyle: {
                        color: 'white',
                        fontSize: '36px'
                    }
                }
            },
        }
    },
    props: {

    },
    watch: {
        // 监听加载中
        loading(newVal) {
            if (this.chart) {
                if (newVal) {
                    this.chart.showLoading();
                } else {
                    this.chart.hideLoading();
                }
            }
        },
        portfolioRoeHistoryChart(val){
            this.getLine();
        }
    },
    created() {
        if(this.responsive == 'mobile'){
            this.option.xAxis.labels.enabled = false;
        }
    },
    mounted() {
        Highcharts.setOptions({
            lang: {
                noData: "",
                contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                printChart: this.$t('highcharts.printChart'),
                resetZoom: this.$t('highcharts.resetZoom'),
                resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                downloadPNG: this.$t('highcharts.downloadPNG'),
                downloadJPEG: this.$t('highcharts.downloadJPEG'),
                downloadPDF: this.$t('highcharts.downloadPDF'),
                downloadSVG: this.$t('highcharts.downloadSVG')
            }
        });
        this.option.credits.style.fontSize = this.responsive == 'mobile' ? '10px' : '12px';
        this.renderChart();
        this.getLine();
    },
    computed: {
        ...mapGetters(['responsive','darkMode']),
        portfolioRoeHistoryChart(){
            return this.$store.getters.portfolioRoeHistoryChart;
        }
    },
    components: {

    },
    props:[],
    methods: {
        getLine(){
            let api = config.baseUrl + "/asset/public/defy/getDefyNavHistory";
            this.$http.get(api).then((res) => {
                let data = res.data;
                if(data.code == 200){
                    let dateArray = ["30 Days","60 Days","1 Quarter","6 Months","1 Year","Since Inception"];
                    let dataArray = [data.result.defy1Month[0].roe, data.result.defy2Month[0].roe, data.result.defy6Month[0].roe, data.result.defy1Year[0].roe, data.result.sinceInception[0].roe];
                    this.option.series[0].data = dataArray;
                    this.option.xAxis.categories = dateArray;
                    this.renderChart();
                }
            }).catch((err) => {
                
            });
        },
        // 渲染图表
        renderChart() {
            this.chart = Highcharts.chart(this.id, this.option);
            this.loading = false;
        },
        noData() {
            Highcharts.setOptions({
                lang: {
                    noData: this.$t('highcharts.noData'),
                    contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                    printChart: this.$t('highcharts.printChart'),
                    resetZoom: this.$t('highcharts.resetZoom'),
                    resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                    downloadPNG: this.$t('highcharts.downloadPNG'),
                    downloadJPEG: this.$t('highcharts.downloadJPEG'),
                    downloadPDF: this.$t('highcharts.downloadPDF'),
                    downloadSVG: this.$t('highcharts.downloadSVG')
                }
            });
            this.renderChart();
        },
    }
}
</script>
